import React, { useCallback, useEffect, useRef } from 'react';

import { Box } from '@/components/shared/layout/box';
import { Image } from '@/components/shared/media/image';
import { generateSplashPageImageSources } from '@/components/shared/media/image/generate-sources';
import { ImageProps } from '@/components/shared/media/image/Image.props';
import { ContentWithAction } from '@/components/shared/my24/content-with-action';
import {
  clearBodyLockScroll,
  enableBodyLockScroll,
} from '@/components/shared/utility/lock-scroll';
import { styled } from '@/stitches.config';
import { IItem, IMy24Tracking } from '@/types/shared';

const Splash = styled('section', {
  '--splash-mobile-width': 360,
  '--splash-mobile-height': 640,
  '--splash-mobile-gap-x': 24,
  '--splash-mobile-gap-y': 40,

  '--splash-tablet-width': 768,
  '--splash-tablet-height': 1024,
  '--splash-tablet-gap-x': 48,
  '--splash-tablet-gap-y': 64,

  '--splash-desktop-width': 1440,
  '--splash-desktop-height': 800,
  '--splash-desktop-gap-x': 104,
  '--splash-desktop-gap-y': 48,
  position: 'fixed',
  width: 'var(--vw)',
  height: 'var(--viewport-height)',
  left: 0,
  top: 0,
  bc: '$black',
  zIndex: '$popup',
  '@supports (height: 100svh)': {
    height: '100svh',
  },
  '@supports (width: 100vw)': {
    width: '100vw',
  },
});

const SplashInner = styled('div', {
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const SplashImageWrap = styled('div', {
  position: 'relative',
  width: '100%',
  height: '100%',
  aspectRatio: '9 / 16',
  fontSize: 0,
  '@lg': {
    aspectRatio: '16 / 9',
  },
  '@maxlg': {
    '@landscape': {
      aspectRatio: '16 / 9',
    },
  },
});

const SplashContent = styled(Box, {
  px: 'calc(var(--splash-mobile-gap-x) * 1px)',
  '@xs': {
    px: 'calc(var(--splash-tablet-gap-x) * 1px)',
  },
  '@lg': {
    px: 'calc(var(--splash-desktop-gap-x) / var(--splash-desktop-width) * 100%)',
  },
  variants: {
    gradient: {
      true: {
        linearGradient:
          'to bottom, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 1) 100%',
      },
    },
  },
});

const SplashContentInner = styled(Box, {
  position: 'relative',
  width: '100%',
  height: '100%',
});

const SplashContentTop = styled(Box, {
  '@portrait': {
    top: `${(40 / 640) * 100 + 'vh'}`,
  },
  '@landscape': {
    top: `${(32 / 360) * 100 + 'vh'}`,
  },
  '@lg': {
    '@landscape': {
      top: `${(64 / 800) * 100 + 'vh'}`,
    },
  },
});

const imagePortrait = {
  aspectRatio: 'var(--splash-logo-m-width) / var(--splash-logo-m-height)',
  width:
    'clamp(calc(var(--splash-logo-m-width) * 1px), calc(var(--splash-logo-m-width) / (var(--splash-mobile-width) - (var(--splash-mobile-gap-x) * 2)) * 100%), calc(var(--splash-logo-w-width) * 1px))',
};

const SplashLogoTop = styled('div', {
  position: 'relative',
  '@lg': {
    aspectRatio: 'var(--splash-logo-w-width) / var(--splash-logo-w-height)',
    width:
      'calc(var(--splash-logo-w-width) / (var(--splash-desktop-width) - (var(--splash-desktop-gap-x) * 2)) * 100%)',
    '@portrait': {
      ...imagePortrait,
    },
  },
  '@maxlg': {
    '@portrait': {
      ...imagePortrait,
    },
    '@landscape': {
      aspectRatio: 'var(--splash-logo-w-width) / var(--splash-logo-w-height)',
      width:
        'clamp(calc(var(--splash-logo-m-width) * 1px), calc(var(--splash-logo-w-width) / (var(--splash-desktop-width) - (var(--splash-desktop-gap-x) * 2)) * 100%), calc(var(--splash-logo-w-width) * 1px))',
    },
  },
});

const SplashContentBottom = styled(Box, {
  '@portrait': {
    bottom: `${(40 / 640) * 100 + 'vh'}`,
  },
  '@landscape': {
    bottom: `${(32 / 360) * 100 + 'vh'}`,
  },
  '@lg': {
    bottom: `${(64 / 800) * 100 + 'vh'}`,
  },
  '& .content-with-action': {
    '& .content-buttons': {
      flexWrap: 'wrap',
      '& > button': {
        order: 2,
        '@sm': {
          order: 1,
        },
      },
      '& > a': {
        order: 1,
        '@sm': {
          order: 2,
        },
        '--bg-color': '#BEFF00',
        '--hover-bg-color': '#98cb09',
        '--icon-color': 'var(--colors-black)',
        '--hover-icon-color': 'var(--colors-white)',
      },
    },
    '& h3 > strong': {
      '@maxlg': {
        display: 'block',
      },
      fontFamily: '$eurothai',
      fontSize: `clamp(24px, ${-360 * ((36 - 24) / 920) + 24}px + ${
        ((36 - 24) / 920) * 100
      }vw, 36px)`,
      lineHeight: '125%',
    },
  },
});

export interface ISplashPage {
  isActive?: boolean;
  image?: ImageProps;
  imageDesktop?: ImageProps;
  bg?: ImageProps;
  bgDesktop?: ImageProps;
  logo?: ImageProps;
  logoDesktop?: ImageProps;
  headline: IItem;
  dataTrack?: IMy24Tracking;
  contentGradient?: boolean;
  onClose?: () => void;
}

const SplashPage: React.FC<ISplashPage> = ({
  headline,
  bg,
  bgDesktop,
  image,
  imageDesktop,
  contentGradient,
  onClose,
  dataTrack = 'lcv-home',
}: ISplashPage) => {
  const SPLASH_REF = useRef<HTMLDivElement>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const disableScrolling = (e: PointerEvent | TouchEvent) => {
    if (e.cancelable) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const onCloseSplash = useCallback(() => {
    clearBodyLockScroll();

    const splashElement = SPLASH_REF.current;
    if (!splashElement) return;

    splashElement.removeEventListener('pointermove', disableScrolling);
    splashElement.removeEventListener('touchmove', disableScrolling);

    if (onClose) {
      onClose();
    }
  }, [disableScrolling, onClose]);

  useEffect(() => {
    const splashElement = SPLASH_REF.current;
    if (!splashElement) return;

    enableBodyLockScroll();
    splashElement.addEventListener('pointermove', disableScrolling, {
      passive: false,
    });
    splashElement.addEventListener('touchmove', disableScrolling, {
      passive: false,
    });

    return () => {
      splashElement.removeEventListener('pointermove', disableScrolling);
      splashElement.removeEventListener('touchmove', disableScrolling);
    };
  }, []);

  return (
    <Splash ref={SPLASH_REF} data-test="splash">
      <SplashInner>
        {!!bg?.src && !!bgDesktop?.src && (
          <SplashImageWrap>
            <Image
              priority
              src={bg?.src}
              alt={bg?.alt}
              width={bg?.width}
              height={bg?.height}
              layout="fill"
              sources={generateSplashPageImageSources({
                mobileSrc: bg?.src,
                desktopSrc: bgDesktop?.src,
              })}
            />
          </SplashImageWrap>
        )}
        <SplashContent
          position="absolute"
          top="0"
          left="0"
          width="full"
          height="full"
          gradient={contentGradient}
        >
          <SplashContentInner>
            {!!image?.src && !!imageDesktop?.src && (
              <SplashContentTop position="absolute" left="0" width="full">
                <SplashLogoTop
                  css={{
                    '--splash-logo-m-width': image?.width,
                    '--splash-logo-m-height': image?.height,
                    '--splash-logo-w-width': imageDesktop?.width,
                    '--splash-logo-w-height': imageDesktop?.height,
                  }}
                >
                  <Image
                    priority
                    src={image?.src}
                    alt={image?.alt}
                    width={image?.width}
                    height={image?.height}
                    layout="fill"
                    objectFit="contain"
                    sources={[
                      {
                        srcset: image?.src,
                        media: { minWidth: 640 },
                        options: { width: 320 },
                      },
                      {
                        srcset: image?.src,
                        media: { maxWidth: 639 },
                        options: { width: 256 },
                      },
                    ]}
                  />
                </SplashLogoTop>
              </SplashContentTop>
            )}
            {!!headline && (
              <SplashContentBottom position="absolute" left="0" width="full">
                <ContentWithAction
                  className="content-with-action"
                  headline={headline}
                  dataTest="splash"
                  dataTrack={dataTrack}
                  onClick={onCloseSplash}
                />
              </SplashContentBottom>
            )}
          </SplashContentInner>
        </SplashContent>
      </SplashInner>
    </Splash>
  );
};

SplashPage.displayName = 'SplashPage';
export default SplashPage;
