import type { CSS } from '@stitches/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Image } from '@/components/shared/media/image';
import { Source } from '@/components/shared/media/image/Image.props';
import { createMediaCondition } from '@/components/shared/media/image/Image.utils';
import { playVideoWithPromiseHandle } from '@/components/shared/utility/video';
import { styled } from '@/stitches.config';

const VideoWrap = styled('div', {
  position: 'absolute',
  inset: '$space-0',
  width: 'initial',
  height: 'initial',
  '& video': {
    position: 'absolute',
    inset: '$space-0',
    width: '0px',
    height: '0px',
    minWidth: '100%',
    maxWidth: '100%',
    minHeight: '100%',
    maxHeight: '100%',
    objectFit: 'cover',
    objectPosition: 'center center',
  },
});

/**
 * Properties for the Video component.
 */
export interface VideoProps {
  /** Optional flag to indicate if the video should be loaded with high priority. If true, the video will be loaded with high priority. */
  imagePriority?: boolean;

  /** Optional boundary margin for lazy loading the video. Specifies the margin around the viewport to start loading the video. */
  imageRootMargin?: string;

  /** URL of the image to be displayed. */
  imageSrc: string;

  /** Alternative text for the image, used for accessibility. */
  imageAlt: string;

  /** Array of sources with device information for the image. */
  imageSources: Source[];

  /**
   * Width of the image in pixels.
   */
  imageWidth: number;

  /**
   * Height of the image in pixels.
   */
  imageHeight: number;

  /** Optional CSS properties to style the video component. */
  css?: CSS;

  /** Optional URL of the video file for mobile devices. If provided, this video will be used for mobile devices. */
  video?: string;

  /** Optional URL of the video file for desktop devices. If provided, this video will be used for desktop devices. */
  videoDesktop?: string;

  /** Optional callback function that is called when the video is ready to play. The event object is passed as an argument. */
  onReady?: (e?: Event) => void;

  /** Callback function that is called when the video has finished playing. The event object is passed as an argument. */
  onVideoEnded?: (e: Event) => void;

  /** Optional flag to prevent the video from loading automatically. If true, the video will not load automatically. */
  preventLoadVideo?: boolean;

  /** Optional flag to indicate if the video should loop when ended. If true, the video will loop when it ends. */
  loop?: boolean;

  /** Optional flag to indicate if the video should play automatically. If true, the video will play automatically. */
  autoplay?: boolean;

  /** Optional flag to indicate if the video should be paused. If true, the video will be paused. */
  paused?: boolean;

  /** Optional attribute used for testing purposes. Can be used to identify the video element in tests. */
  dataTest?: string;
}
export const DEFAULT_VIDEO_SRC =
  'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

export const Video: React.FC<VideoProps> = ({
  css,
  imagePriority,
  imageRootMargin = '0px 0px 0px -2px',
  imageSrc,
  imageAlt,
  imageWidth,
  imageHeight,
  imageSources,
  video,
  videoDesktop,
  onReady,
  onVideoEnded,
  preventLoadVideo,
  loop,
  autoplay,
  paused,
  dataTest,
}) => {
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const [currentVideoSrc, setCurrentVideoSrc] =
    useState<string>(DEFAULT_VIDEO_SRC);
  const videoRef = useRef(null);

  const onImageLoad = useCallback(() => {
    setImageLoaded(true);
  }, []);

  const handleLoadedMetadata = useCallback(
    (e) => {
      if (!videoRef.current) return;

      if (!autoplay) {
        videoRef.current.pause();
      }
      onReady?.(e);
    },
    [autoplay, onReady]
  );

  const handleVideoEnded = useCallback(
    (e) => {
      if (videoRef.current?.ended) {
        onVideoEnded && onVideoEnded(e);
      }
    },
    [onVideoEnded]
  );

  useEffect(() => {
    if (video === '' && videoDesktop === '') {
      onReady?.();
      return;
    }

    if (preventLoadVideo) return;

    if (imagePriority || imageLoaded) {
      for (const source of imageSources) {
        const media = createMediaCondition(source.media);
        if (window.matchMedia(media).matches) {
          switch (source.device) {
            case 'mobile':
              setCurrentVideoSrc(video);
              break;
            case 'desktop':
              setCurrentVideoSrc(videoDesktop);
              break;
          }
          break;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    videoDesktop,
    video,
    preventLoadVideo,
    imagePriority,
    imageSources,
    imageLoaded,
  ]);

  useEffect(() => {
    if (!videoRef.current || !currentVideoSrc) return;

    if (paused && !videoRef.current?.paused) {
      videoRef.current?.pause();
    } else if (videoRef.current?.paused) {
      playVideoWithPromiseHandle(videoRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paused]);

  return (
    <VideoWrap css={css}>
      <Image
        priority={imagePriority}
        rootMargin={imageRootMargin}
        src={imageSrc}
        alt={imageAlt}
        width={imageWidth}
        height={imageHeight}
        layout="fill"
        sources={imageSources}
        onLoad={onImageLoad}
      />
      {!!video && !!videoDesktop && (
        <video
          ref={videoRef}
          preload="none"
          autoPlay
          muted
          playsInline
          src={currentVideoSrc}
          onLoadedMetadata={handleLoadedMetadata}
          onEnded={handleVideoEnded}
          data-test={dataTest}
          loop={loop}
        />
      )}
    </VideoWrap>
  );
};

Video.displayName = 'Video';
